<template>
  <div class="mine container">
      <side class="side"></side>
      <div class="rightMain">
            <router-view/>
      </div>
      
  </div>
</template>

<script>
import Side from './components/Side'
export default {
  name: 'Mine',
  components:{Side},  
  data () {
    return {
       
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.mine.container{
    background: #fff;
    box-shadow: 0px 6px 16px 0px rgba(0, 109, 117, 0.06);
    border-radius: 8px;
    margin: 16px auto;
    min-height: 670px;
    padding-right: 0;
    /deep/ .el-collapse-item__header{
      line-height: 1.5em;
      height: auto;
      padding:12px;
    }
  }
.mine{
    position: relative;
    padding-left: 192px;
    .side{
        width:192px;
        position: absolute;
        left: 0;
        top:0;
        bottom: 0;
    }
}
@media (max-width: 992px){
   .mine{
   padding-left:18vw;
    .side{
      width:18vw;
    }
    .container-right{
      padding:0 16px;
    }
}
}

@media (max-width: 576px){
    .mine{
    position:static;
    padding-left:0;
    .side{
        width:100%;
        position: static;
    }
}
}
</style>
